import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import Switcher from "../component/Switcher";

import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";

import single1 from "../assets/images/property/single/1.jpg";
import single2 from "../assets/images/property/single/2.jpg";
import single3 from "../assets/images/property/single/3.jpg";
import single4 from "../assets/images/property/single/4.jpg";
import single5 from "../assets/images/property/single/5.jpg";
import { LiaCompressArrowsAltSolid } from "react-icons/lia";
import { LuBath, LuBedDouble } from "react-icons/lu";
import { FiMapPin, FiPhone } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { enquiry, getProperty } from "../actions/PropertyActions";
import Loader from "../component/Loader";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";

export default function PropertyDetailTwo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  let id = params.id;

  const { loading, property, isEnquirySuccess } = useSelector(
    (state) => state.propertyState
  );

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(enquiry(id, navigate, formData));
  };

  const settings = {
    container: ".tiny-one-item",
    items: 1,
    controls: true,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    controlsText: [
      '<i class="mdi mdi-chevron-left "></i>',
      '<i class="mdi mdi-chevron-right"></i>',
    ],
    nav: false,
    speed: 400,
    gutter: 0,
  };

  let images = [single1, single2, single3, single4, single5];

  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log("property details working");
    dispatch(getProperty(id));
  }, [dispatch, id]);

  useEffect(() => {
    setOpen(false);
  }, [isEnquirySuccess]);

  function daysSinceCreation(createdAt) {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDifference = currentDate - createdDate;
    return Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Convert to days
  }
  const formatDate = (createdAt) => {
    const options = { year: "numeric", month: "long", day: "numeric" }; // e.g., November 19, 2024
    return new Date(createdAt).toLocaleDateString(undefined, options);
  };

  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(2) + " CR"; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(2) + " L"; // Lakhs
    } else {
      return num.toString(); // Less than 1 Lakh
    }
  };

  return (
    <>
      <Navbar />

      {loading && property < 0 ? (
        <Loader />
      ) : (
        <section className="relative md:py-24 pt-24 pb-16">
          <div className="container relative">
            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
              <div className="lg:col-span-8 md:col-span-7">
                <div className="grid grid-cols-1 relative">
                  <div className="tiny-one-item">
                    <TinySlider settings={settings}>
                      {property &&
                      property.imageUrls &&
                      property.imageUrls.length > 0 ? (
                        property.imageUrls.map((item, index) => {
                          return (
                            <div className="tiny-slide" key={index}>
                              <img
                                src={item.image}
                                className="rounded-md shadow dark:shadow-gray-700"
                                alt={`property-image-${index}`}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <p>No images available</p>
                      )}
                    </TinySlider>
                  </div>
                </div>

                <h4 className="text-2xl font-medium mt-6 mb-3">
                  {property?.name}
                </h4>
                <span className="text-slate-400 flex items-center">
                  <FiMapPin className="size-5 me-2" /> {property?.address}
                  ,&nbsp;{property?.district},&nbsp;{property?.state}
                </span>

                <ul className="py-6 flex items-center list-none">
                  <li className="flex items-center lg:me-6 me-4">
                    <LiaCompressArrowsAltSolid className="lg:text-3xl text-2xl me-2 text-green-600" />
                    <span className="lg:text-xl">
                      {(property?.square && (
                        <span>{property?.square}&nbsp;sqft</span>
                      )) ||
                        (property?.cent && (
                          <span>{property?.cent}&nbsp;Cent</span>
                        ))}
                    </span>
                  </li>

                  {/* <li className="flex items-center lg:me-6 me-4">
                    <LuBedDouble className="lg:text-3xl text-2xl me-2 text-green-600" />
                    <span className="lg:text-xl">4 Beds</span>
                  </li>

                  <li className="flex items-center">
                    <LuBath className="lg:text-3xl text-2xl me-2 text-green-600" />
                    <span className="lg:text-xl">4 Baths</span>
                  </li> */}
                </ul>

                <p className="text-slate-400">{property?.description}</p>
                <br />
                <p className="text-slate-400">{property?.descriptionTwo}</p>

                <br />
                <br />
                {property?.propertyID && (
                <h3 className="text-slate-600">
                  PROPERTY ID: {property?.propertyID}
                </h3>
                )}
                {/* <p className="text-3slate-400 mt-4">
                  But I must explain to you how all this mistaken idea of
                  denouncing pleasure and praising pain was born and I will give
                  you a complete account of the system, and expound the actual
                  teachings of the great explorer of the truth, the
                  master-builder of human happiness.
                </p>
                <p className="text-slate-400 mt-4">
                  Nor again is there anyone who loves or pursues or desires to
                  obtain pain of itself, because it is pain, but because
                  occasionally circumstances occur in which toil and pain can
                  procure him some great pleasure.
                </p> */}
                {property?.gmap ? (
                  <div className="w-full leading-[0] border-0 mt-6">
                    <iframe
                      src={property?.gmap}
                      style={{ border: "0" }}
                      title="myframe"
                      className="w-full h-[500px]"
                      allowFullScreen
                    ></iframe>
                  </div>
                ) : null}
              </div>

              <div className="lg:col-span-4 md:col-span-5">
                <div className="sticky top-20">
                  <div className="rounded-md bg-slate-50 dark:bg-slate-800 shadow dark:shadow-gray-700">
                    <div className="p-6">
                      <h5 className="text-2xl font-medium">Price:</h5>

                      <div className="flex justify-between items-center mt-4">
                        <span className="text-xl font-medium">
                          ₹&nbsp;{formatNumber(property?.regularPrice || 0)}
                        </span>

                        {property?.propertyStatus === "Sold" ? (
                          <span className="bg-red-600/10 text-red-600 text-sm px-2.5 py-0.75 rounded h-6">
                            {property?.propertyStatus}
                          </span>
                        ) : (
                          <span className="bg-green-600/10 text-green-600 text-sm px-2.5 py-0.75 rounded h-6">
                            {property?.propertyStatus}
                          </span>
                        )}
                      </div>

                      <ul className="list-none mt-4">
                        <li className="flex justify-between items-center">
                          <span className="text-slate-400 text-sm">
                            Days on My brokerr
                          </span>
                          <span className="font-medium text-sm">
                            {property?.createdAt
                              ? daysSinceCreation(property.createdAt) === 0
                                ? "Today"
                                : daysSinceCreation(property.createdAt) === 1
                                ? "Yesterday"
                                : daysSinceCreation(property.createdAt) < 7
                                ? `${daysSinceCreation(
                                    property.createdAt
                                  )} days ago`
                                : formatDate(property.createdAt)
                              : "Unknown date"}
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="p-1 w-full p-4">
                      <Link
                        onClick={() => setOpen(true)}
                        className="btn bg-green-600 filterBtn text-white rounded-md w-full"
                      >
                        Enquire Now
                      </Link>
                    </div>
                  </div>

                  <div className="mt-12 text-center">
                    <h3 className="mb-6 text-xl leading-normal font-medium text-black dark:text-white">
                      Have Question ? Get in touch!
                    </h3>

                    <div className="mt-6">
                      <Link
                        to="/contact"
                        className="btn bg-transparent filterBtn border text-green-600 hover:text-white rounded-md"
                      >
                        <FiPhone className="align-middle me-2  " /> Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <Footer />
      {/* <Switcher /> */}

      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto P-5">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0 ">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-5"
            >
              <div className="bg-white px-6 pt-5 pb-4 sm:p-6">
                <div className="sm:flex sm:items-center flex justify-center mb-4">
                  <DialogTitle
                    as="h2"
                    className="ml-4 text-2xl font-bold text-gray-700"
                  >
                    GET A CALL BACK.
                  </DialogTitle>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4 ">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name ..."
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email ..."
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="Mobile ..."
                      value={formData.phone}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="flex justify-end space-x-3 mt-5">
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      className="inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow-sm filterBtn"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
