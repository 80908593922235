import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { properties } from "./data";
import { LiaCompressArrowsAltSolid } from "react-icons/lia";
import { BiLogoWhatsapp, BiMap } from "react-icons/bi";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { enquiry, getPropertyList } from "../../actions/PropertyActions";
import Loader from "../Loader";
import PhoneInput from "react-phone-input-2";

export default function Property() {
  const dispatch = useDispatch();
  const { propertyList, loading, isEnquirySuccess } = useSelector(
    (state) => state.propertyState
  );
  let items = propertyList;
  const [searchTerm, setSearchTerm] = useState("");
  const [offer, setOffer] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("Featured");
  const [open, setOpen] = useState(false);
  const [passingId, SetPassingId] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  const enquireHandler = (e, id) => {
    // e.target.disabled = true;
    setOpen(true);
    SetPassingId(id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(enquiry(passingId, formData));
    SetPassingId("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
  };

  useEffect(() => {
    dispatch(getPropertyList(searchTerm, offer, propertyStatus));
  }, [dispatch]);

  useEffect(() => {
    setOpen(false);
  }, [isEnquirySuccess]);

  const formatNumber = (num) => {
    if (num >= 10000000) {
      return (num / 10000000).toFixed(2) + " CR"; // Crores
    } else if (num >= 100000) {
      return (num / 100000).toFixed(2) + " L"; // Lakhs
    } else {
      return num.toLocaleString("en-IN"); // Less than 1 Lakh
    }
  };

  return (
    <>
      <div className="container lg:mt-24 mt-16">
        {/* <div className="grid grid-cols-1 pb-8 text-center">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            Featured Properties
          </h3>

          <p className="text-slate-400 max-w-xl mx-auto">
            A great plateform to buy, sell and rent your properties without any
            agent or commisions.
          </p>
        </div> */}

        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
          {loading ? (
            <Loader />
          ) : items && items.length > 0 ? (
            items.map((item, index) => (
              <div
                className="group rounded-xl bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-700 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500"
                key={index}
              >
                <div className="relative">
                  <Link
                    to={`/property-detail/${item._id}`}
                    className="text-lg mainTextHover font-medium ease-in-out duration-500"
                  >
                    <img src={item.imageUrls[0].image} alt="" />
                  </Link>
                </div>

                <div className="p-6">
                  <div className="pb-3">
                    <Link
                      to={`/property-detail/${item._id}`}
                      className="text-lg mainTextHover font-medium ease-in-out duration-500 overflow-hidden whitespace-nowrap text-ellipsis block"
                      style={{ maxWidth: "450px" }} 
                    >
                      {item.name}
                    </Link>
                  </div>

                  <ul className="py-3 border-y border-slate-100 dark:border-gray-800 flex items-center justify-between list-none">
                    <li className="flex items-center me-4">
                      <LiaCompressArrowsAltSolid className="text-2xl me-2 text-green-600" />
                      {(item.square && <span>{item.square} sqft</span>) ||
                        (item.cent && <span>{item.cent}Cent</span>)}
                    </li>

                    <li className="flex items-center me-4">
                      <BiMap className="text-2xl me-2 text-green-600" />
                      <span>{item.address}</span>
                    </li>
                  </ul>

                  <ul className="pt-6 flex justify-between items-center list-none">
                    <li>
                      <span className="text-slate-400">Price</span>
                      <p className="text-lg font-medium">
                        ₹&nbsp;{formatNumber(item.regularPrice)}
                      </p>
                    </li>

                    {/* <button
              onClick={(e) => enquireHandler(e, item._id)}
              className="bg-transparent callBackBtn text-green-600 font-semibold py-2 px-4 ml-1 border border-green-600 rounded"
            >
              Get a call back
            </button> */}

                    <div className="flex">
                      <li>
                        <button
                          onClick={() =>
                            window.open("https://wa.me/9746374542", "_blank")
                          }
                          className="flex items-center me-1 gap-1 px-2 py-1 border border-[#128C7E] rounded-md text-white bg-[#128C7E] hover:text-white"
                        >
                          <BiLogoWhatsapp className="text-3xl" />
                        </button>
                      </li>

                      {/* Call Back Button */}
                      <li>
                        <button
                          onClick={(e) => enquireHandler(e, item._id)}
                          className="btn bg-green-600 px-3 py-2 filterBtn text-white rounded-md"
                        >
                          Get a call back
                        </button>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className="flex items-center justify-center">
                <div className="grid grid-cols-1 gap-10 text-center">
                  <div className="flex flex-col items-center justify-center">
                    <h1 className="text-2xl font-semibold text-gray-700">
                      Oops No Properties Found
                    </h1>
                    <p className="text-gray-500">
                      We couldn’t find any properties matching your search
                      criteria.
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="grid grid-cols-1 pt-10 text-center">
          <div className="grid grid-cols-1 text-center">
            <div className="mt-6">
              <Link
                to="/explore"
                className="btn bg-green-600 filterBtn text-white rounded-md"
              >
                Explore more
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto P-5">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0 ">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg p-5"
            >
              <div className="bg-white px-6 pt-5 pb-4 sm:p-6">
                <div className="sm:flex sm:items-center flex justify-center mb-4">
                  <DialogTitle
                    as="h2"
                    className="ml-4 text-2xl font-bold text-gray-700"
                  >
                    GET A CALL BACK.
                  </DialogTitle>
                </div>

                <form onSubmit={handleSubmit} className="space-y-4 ">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm sm:text-sm"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm sm:text-sm"
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone
                    </label>
                    <PhoneInput
                      country={"in"} // Default country
                      value={formData.phone}
                      onChange={handlePhoneChange}
                      inputProps={{
                        name: "phone",
                        required: true,
                        maxValue: 13,
                        id: "phone",
                        className:
                          "mt-1 block w-full rounded-md border border-gray-300 px-12 py-2 shadow-sm  sm:text-sm",
                      }}
                      containerClass="mb-4"
                      placeholder="Mobile number"
                    />
                  </div>

                  <div className="flex justify-end space-x-3 mt-5">
                    <button
                      type="button"
                      onClick={() => setOpen(false)}
                      className="inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="inline-flex filterBtn justify-center rounded-md bg-green-600 px-4 py-2 text-sm font-semibold text-white shadow-sm "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
